<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot> </template>
      <template #searchSlot>
        <v-input
          label="昵称"
          v-model="searchParam.nickName"
          placeholder="请输入昵称"
        />
        <v-input
          label="手机号"
          v-model="searchParam.phone"
          placeholder="请输入手机号"
        />
        <v-select
          clearable
          :options="communityList"
          v-model="searchParam.spaceId"
          label="小区"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" @click="check(scope.row)" />
      </template>
    </v-list>

    <v-dialog
      :title="dialogTitle"
      cancelTxt="返回"
      v-model="dialogFormVisible"
      :showConfirmButton="false"
      oStyle="justify-content: center;"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="签名：">
          <div class="pic_box">
            <img :src="temp.signaturePicture" alt="" />
          </div>
        </el-form-item>
        <el-form-item label="昵称：">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="temp.nickName"
          ></v-input>
        </el-form-item>
        <el-form-item label="手机号：">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="temp.phone"
          ></v-input>
        </el-form-item>
        <el-form-item label="地址：">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="temp.spaceName"
          ></v-input>
        </el-form-item>
        <el-form-item label="意见建议：">
          <v-input
            disabled
            clearable
            class="filter-item"
            v-model="temp.opinionsSuggestions"
          ></v-input>
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  neighborhoodSignRecordListUrl,
  neighborhoodConventionListUrl,
  communityListUrl,
  updateNeighborhoodConventionUrl,
} from "./api.js";
import { getOption, createImgVNode } from "@/utils/utils.js";
import { releaseStatusMap, releaseStatus, releaseStatusOps } from "./map.js";
export default {
  name: "signatureLst",
  data() {
    return {
      releaseStatusOps: releaseStatusOps(),
      releaseStatus,

      dialogTitle: "查看",
      dialogFormVisible: false,
      searchParam: {
        nickName: null,
        phone: null,
        spaceId: null,
      },
      tableUrl: neighborhoodSignRecordListUrl,
      headers: [
        {
          prop: "signaturePicture",
          label: "签名",
          align: "center",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "nickName",
          label: "昵称",
        },
        {
          prop: "phone",
          label: "手机号",
        },
        {
          prop: "spaceName",
          label: "地址",
        },
        {
          prop: "opinionsSuggestions",
          label: "意见建议",
        },
      ],
      communityList: [],
      temp: {
        signaturePicture: null,
        nickName: null,
        phone: null,
        spaceName: null,
        opinionsSuggestions: null,
      },
    };
  },
  mounted() {
    this.getCommunityList();
  },
  methods: {
    getCommunityList() {
      //获取小区
      let params = {
        specifyCollectionTypes: 10,
      };
      this.$axios
        .get(`${communityListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((item) => {
              item.value = item.id;
              item.label = item.name;
            });
            this.communityList = res.data;
            console.log(this.communityList);
          }
        });
    },
    check(data) {
      //查看按钮
      console.log(data);
      this.dialogFormVisible = true;
      this.temp.signaturePicture = data.signaturePicture;
      this.temp.nickName = data.nickName;
      this.temp.phone = data.phone;
      this.temp.spaceName = data.spaceName;
      this.temp.opinionsSuggestions = data.opinionsSuggestions;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;

  .pic_box {
    width: 100%;
    height: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .filter-item {
    ::v-deep .el-input {
      width: 100% !important;
    }
  }
}
</style>
